@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@page { size: auto;  margin: 0mm; }

#root {
  margin-top: 56px;
}

@media (min-width: 0px) and (orientation: landscape) {
  #root{
    margin-top: 48px;
  }
}

@media (min-width: 600px) {
  #root {
    margin-top: 64px;
  }
}

@media print {
    body {
        -webkit-print-color-adjust: exact !important;
    }
    #root {
        margin-top: 0;
    }
    hr {
        border-top: solid 1px rgba(0, 0, 0, 0.12) !important;
    }
    li {
        page-break-inside: avoid;
    }
    .break-print{
        clear: both;
        page-break-after: always;
        page-break-after: always;
        break-after: page;
    }
}

div[id^="toggle-button-group"] > button {
  border-right: 1px solid;
}

div[id^="toggle-button-group"] > button > span > p {
  font-weight: 500 !important;
}

div[id^="toggle-button-group"] > button:last-child {
  border-right: none;
}

button[class*="selected-toggle-button"] {
  background-color: #ff9100 !important;
}

button[class*="disabled-toggle-button"] {
    background-color: #ccc !important;
  }

